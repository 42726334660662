import * as Sentry from '@sentry/browser'
import React, { createContext, useContext } from 'react'

interface IErrorBoundaryProps {
  fallback: (error?: Error) => React.ReactNode
  extraTags?: Record<string, string>
  /**
   * Callback invoked just before clearing the error so we can optionally try to recitfy whatever
   * caused an exception.
   */
  onRecoverAttempt?: () => void
}
interface IErrorBoundaryState {
  error?: Error
  numRecoveryAttempts: number
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  public state = {
    error: undefined,
    numRecoveryAttempts: 0,
  }

  componentDidCatch(error: Error, errorInfo: { [key: string]: any }) {
    const tags = {
      layer: 'react',
      ...this.props.extraTags,
    }
    if (window.CoderPad.ENVIRONMENT === 'development') {
      console.error('React error', error, tags)
    } else {
      Sentry.captureException(error, { tags, extra: errorInfo })
    }
  }

  onRecoverAttempt = () => {
    this.props.onRecoverAttempt?.()
    this.setState((prev) => ({
      error: undefined,
      numRecoveryAttempts: prev.numRecoveryAttempts + 1,
    }))
  }

  render() {
    return (
      <ReloadableProvider
        attemptRecovery={this.onRecoverAttempt}
        numRecoveryAttempts={this.state.numRecoveryAttempts}
      >
        <>{this.state.error ? this.props.fallback(this.state.error) : this.props.children}</>
      </ReloadableProvider>
    )
  }
}

const ReloadableContext = createContext<{
  attemptRecovery: () => void
  hasAttemptedRecovery: boolean
}>({
  attemptRecovery: () => {},
  hasAttemptedRecovery: false,
})

interface ReloadableProviderProps {
  attemptRecovery: () => void
  children: React.ReactNode
  numRecoveryAttempts?: number
}
function ReloadableProvider({
  attemptRecovery,
  children,
  numRecoveryAttempts,
}: ReloadableProviderProps) {
  return (
    <ReloadableContext.Provider
      value={{ attemptRecovery, hasAttemptedRecovery: (numRecoveryAttempts ?? 0) > 0 }}
    >
      {children}
    </ReloadableContext.Provider>
  )
}

export function useErrorBoundaryRecover() {
  return useContext(ReloadableContext)
}
